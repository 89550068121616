import "./App.css";
import Home from "./Home.component";

function App() {
  return (
    <div>
      {/* <Home /> */}
    </div>
  );
}

export default App;